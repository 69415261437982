.FormBlock {
    position: relative;
    margin: 64px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    backdrop-filter: blur(17.5px);
    border-radius: 30px;
    border: 2px solid #FF2A23;

    &_Title {
        padding: 48px 24px 20px;
        font-size: 40px;
        text-align: center;
        font-weight: 600;
        color: #111;
    }

    .Form {
        width: 100%;
        height: 100%;
        padding: 0 24px 70px;
        display: flex;
        flex-direction: column;
        gap: 16px;

        .FirstLine {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
        }

        .SecondLine {
            display: flex;
            align-items: center;
            gap: 16px;
            width: 100%;
        }

        .Label {
            color: #111;
            margin: 0px 0 8px 0px;
            font-family: Inter;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
        }

        .PaymentTypes {
            display: flex;
            width: 260px;
            height: 60px;
            background-color: #fff;
            border-radius: 100px;

            &__Type {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
                border-radius: 100px;
                width: 50%;
                cursor: pointer;

                span {
                    color: #111;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }

                &Active {
                    background-color: #FF2A23;
                    span {
                        color: #fff;
                    }
                }
            }
        }

        .PaymentInfo {
            display: flex;
            align-items: start;
            margin-top: 20px;
            color: #111;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;

            img {
                width: 16px;
                height: 16px;
                margin-right: 8px;
            }
        }

        .Sums {
            position: relative;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .DonationSums {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 8px;
            width: 400px;

            &__Sum {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-basis: 24%;
                height: 60px;
                border-radius: 100px;
                background-color: #fff;
                cursor: pointer;

                span {
                    color: #111;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 30px;
                }

                &Active {
                    outline: 1px solid #FF2A23;

                    span {
                        color: #FF2A23;
                    }
                }
            }
        }

        .DonationInputSum {

            &__Input {
                width: 150px;
                background-color: #fff;
                outline: none;
                border: none;
                border-radius: 100px;
                padding: 15px 30px 15px;
                color: #111;
                font-family: Inter;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 30px;
            }
        }

        .DonationInputSum__Text {
            position: absolute;
            left: 0px;
            bottom: -24px;
            margin-top: 10px;
            color: #111;
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            opacity: 0.5;
        }

        .CheckBoxes {
            display: flex;
            flex-direction: column;
            justify-content: end;
            margin-top: 24px;

            div {
                display: flex;
            }

            input {
                position: absolute;
                z-index: -1;
                opacity: 0;
            }
            input+label {
                user-select: none;
            }
            input+label::before {
                content: '';
                display: inline-block;
                width: 16px;
                height: 16px;
                flex-shrink: 0;
                flex-grow: 0;
                border: 1px solid #111;
                border-radius: 4px;
                margin-right: 8px;
                background-repeat: no-repeat;
                background-position: center center;
                background-size: 50% 50%;
            }
            input:checked+label::before {
                background-image: url("../../../new-images/donation/check.svg");
                background-size: 70%;
            }

            p {
                color: #A9B0C2;
                font-family: Inter;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 20px;
                span {
                    color: #FF2A23;
                }
            }
        }

        .DonationButton {
            width: 100%;
            display: flex;
            margin-top: 32px;

            button {
                width: 320px;
                height: 60px;
                padding: 10px 30px;
                border: none;
                outline: none;
                border-radius: 100px;
                background-color: #FF2A23;
                color: #fff;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            &WithBack {
                justify-content: space-between;
            }
        }

        .Comment {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            label {
                color: #111;
                margin: 0px 0 8px 0px;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }

            textarea {
                height: 148px;
                width: 700px;
                border: none;
                outline: none;
                resize: none;
                border-radius: 30px;
                background-color: #fff;
                padding: 30px;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px; 
            }

            span {
                position: absolute;
                bottom: 16px;
                right: 30px;
                color: #A9B0C2;
            }
        }

        .ContactBlock {
            display: flex;
            align-items: center;
            gap: 8px;

            &__Input {
                display: flex;
                flex-direction: column;
                width: 100%;

                label {
                    color: #111;
                    font-family: Inter;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;

                    span {
                        color: #FF2A23;
                    }
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    background-color: #fff;
                    border-radius: 100px;
                    padding: 15px 30px 15px;
                    color: #111;
                    font-family: Inter;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                }

                input[disabled] {
                    color: #A9B0C2;
                }
            }
        }

        .ErrorInput {
            outline: 1px solid #FF2A23 !important;
        }
        
        .ErrorText {
            position: absolute;
            left: 0px;
            bottom: -24px;
            color: #FF2A23 !important;
        }

        .ErrorCheckBox {
            &+label::before {
                border: 1px solid #FF2A23 !important;
            }
        }

        &Success {
            background-image: url('../../../new-images/donation/bg-success.svg');
            background-repeat: no-repeat;
            background-position: bottom;
            height: 380px;

            button {
                width: 340px;
                height: 60px;
                padding: 10px 30px;
                border: none;
                outline: none;
                border-radius: 100px;
                background-color: #FF2A23;
                margin: 0 auto;
                color: #fff;
                font-family: Inter;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
            }
        }
    }
}

@media (max-width: 1919px) {
    .FormBlock  {

        .Form {
            padding: 0 24px 70px;

            .Label {
                font-size: 14px;
                line-height: 24px;
            }

            .PaymentTypes {
                height: 48px;
                &__Type {
                    span {
                        font-size: 14px;
                    }
                }
            }
            
            .PaymentInfo {
                font-size: 12px;
                line-height: 14px;
            }

            .DonationSums {
                width: 340px;
                &__Sum {
                    height: 48px;
                    span {
                        font-size: 14px;
                    }
                }
            }
            
            .DonationInputSum {
                &__Input {
                    padding: 12px 8px 12px 16px;
                    font-size: 14px;
                    line-height: 24px;
                }

                &__Text {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            
            .DonationButton {

                button {
                    height: 48px;
                    padding: 8px 24px;
                }
            }

            .Comment {
                margin-top: 10px;
                label {
                    margin: 20px 0 8px 0px;
                    font-size: 14px;
                }

                textarea {
                    width: 600px;
                    height: 180px;
                    padding: 16px;
                    font-size: 16px;
                }

                span {
                    right: 20px;
                }
            }

            .ContactBlock {
                &__Input {
                    label {
                        font-size: 14px;
                        line-height: 24px;
                    }
    
                    input {
                        border-radius: 100px;
                        padding: 12px 8px 12px 16px;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media (max-width: 1439px) {
    .FormBlock  {

        &_Title {
            font-size: 36px;
            margin-bottom: 24px;
            padding: 32px 24px 20px;
        }

        .Form {
            padding: 0 24px 32px;
            gap: 32px;

            .FirstLine {
                flex-wrap: wrap;
                row-gap: 32px;
            }

            .ContactBlock {
                gap: 16px;

                &__Input {
                    input {
                        width: 260px;
                    }
                }
            }

            .Comment {
                margin-top: 0;

                label {
                    margin: 0 0 8px 0px;
                }
            }

            .ErrorText {
                left: 0px;
                bottom: -20px;
                font-size: 10px;
                color: #FF2A23 !important;
            }

            .DonationInputSum__Text {
                left: 0px;
                bottom: -20px;
                font-size: 10px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .FormBlock  {

        &_Title {
            font-size: 28px;
            margin-bottom: 24px;
            padding: 32px 24px 10px;
        }

        .Form {

            .SecondLine {
                flex-direction: column;
                align-items: start;
            }

            .ContactBlock {
                gap: 16px;

                &__Input {
                    input {
                        width: 206px;
                    }
                }
            }

            .Comment {
                width: 100%;
                textarea {
                    width: 100%;
                    height: 160px;
                    font-size: 14px;
                }
            }

            .CheckBoxes {
                display: grid;
                grid-template-columns: 0.55fr 0.45fr;
                width: 100%;
                gap: 20px;
                row-gap: 6px;
                margin-top: 0px;

                p {
                    font-size: 11px;
                }

                div:nth-child(1) {
                    order: 1;
                }

                div:nth-child(2) {
                    order: 3;
                }

                div:nth-child(3) {
                    order: 2;
                }

                div:nth-child(4) {
                    order: 4;
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .FormBlock  {

        &_Title {
            font-size: 20px;
            margin-bottom: 24px;
            padding: 24px 24px 0px;
        }

       .Form {
            padding: 0px 14px 24px;
            row-gap: 16px;

            &Success {
                background-size: 80%;
                height: 280px;
    
                button {
                    width: 280px;
                    height: 48px;
                    font-size: 16px;
                    margin-top: 40px;
                }
            }

            .Label {
                display: none;
            }

            .FirstLine {
                row-gap: 18px;
            }

            .PaymentTypes {
                width: 280px;
                height: 40px;
                &__Type {
                    span {
                        font-size: 12px;
                    }
                }
            }

            .Sums {
                flex-wrap: wrap;
                row-gap: 16px;
            }

            .DonationSums {
                width: 280px;
                &__Sum {
                    height: 40px;
                    span {
                        font-size: 12px;
                    }
                }
            }

            .DonationInputSum {
                width: 100%;
                input {
                    width: 100%;
                    font-size: 12px;
                }
            }

            .ContactBlock {
                margin-top: 8px;
                flex-wrap: wrap;
                gap: 8px;

                &__Input {

                    width: 136px;

                    input {
                        width: 100%;
                        padding: 12px 8px 12px 16px;
                        font-size: 12px;
                    }

                    label {
                        font-size: 12px;
                        margin-bottom: 0;
                    }

                    &:nth-child(3) {
                        width: 280px;
                    }
                }
            }

            .Comment {
                label {
                    font-size: 12px;
                    margin-bottom: 0;
                }

                textarea {
                    font-size: 12px;
                    line-height: 16px;
                }

                span {
                    font-size: 10px;
                }
            }

            .CheckBoxes {
                display: flex;
                flex-direction: column;

                p {
                    font-size: 9px;
                    line-height: 16px;
                }

                div:nth-child(1) {
                    order: 1;
                }

                div:nth-child(2) {
                    order: 2;
                }

                div:nth-child(3) {
                    order: 3;
                }

                div:nth-child(4) {
                    order: 4;
                }
            }

            .DonationButton {
                margin-top: 8px;
                button {
                    font-size: 14px;
                }
            }

            .ErrorText {
                left: 16px;
                bottom: -16px;
                font-size: 10px;
                color: #FF2A23 !important;
            }

            .DonationInputSum__Text {
                left: 16px;
                bottom: -16px;
                font-size: 10px;
            }
       }
    }
}